import 'styles/main.scss';

import $ from 'jquery';

import headerSearch from 'scripts/modules/header-search';
import modal from 'scripts/modules/modal';
import secondaryNav from 'scripts/modules/secondary-nav';
import informational from 'scripts/modules/informational';
import toggle from 'scripts/modules/toggle';
import image from 'scripts/modules/image';
import video from 'scripts/modules/video';
import carousel from 'scripts/modules/carousel';
import thumbGrid from 'scripts/modules/thumb-grid';
import printPage from 'scripts/modules/print-page';
import fbCustomerChat from 'scripts/modules/fb-customerchat';
import publicUpdate from 'scripts/pages/public-update';
import publicUpdateModal from 'scripts/modules/public-update__modal';
import 'scripts/pages/pressroom-impact.js';
import 'scripts/pages/photo-album.js';

const $headerSearch = $('#header-search');

function donationBarStyles () {
    const $donationLink = $('.donation-bar-link');

    if ($donationLink.length) {
        const $body = $('body');
        const h = $donationLink.height();
        const btp = $(window).width() >= 840 ? 160 : 100;
        $body.css('paddingTop', `${h + btp}px`);
    }
}

$(document).ready(function () {
    donationBarStyles();

    $(window).resize(function () {
        donationBarStyles();
    });

    if ($('aside.informational__aside').length && $('article.informational').length) {
        $(window).resize(function () {
            calculateHeight();
        });
        calculateHeight();
    }

    function calculateHeight () {
        const windowWidth = $(window).width();

        if (windowWidth >= 840) {
            setTimeout(function () {
                const asideHeight = $('aside.informational__aside').css('height');
                $('article.informational').css({ minHeight: asideHeight });
            }, 500);
        }
    }

    $('a[href^="http://"]').attr('target', '_blank');
    $('a[href^="https://"]').attr('target', '_blank');

    if ($('.daily-donate-overlay').length > 0) {
        setTimeout(function () {
            if (document.cookie.indexOf('amdoc_donate_daily=') === -1 && $('body.page.home').length === 1) {
                $('#donate-modal-overlay').addClass('show-modal');
                const expiryDate = new Date();
                expiryDate.setTime(expiryDate.getTime() + (24 * 60 * 60 * 1000) - (60 * 1000));
                document.cookie = `amdoc_donate_daily=yes; expires=${expiryDate}`;
            }
        }, 5000);
    }

    $('#donate-modal-overlay .donate-modal-close').on('click', function () {
        $('#donate-modal-overlay').removeClass('show-modal');
    });
});

if (typeof Object.assign !== 'function') {
    // Must be writable: true, enumerable: false, configurable: true
    Object.defineProperty(Object, 'assign', {
        value: function assign (target, varArgs) { // .length of function is 2
            'use strict';
            if (target === null || target === undefined) {
                throw new TypeError('Cannot convert undefined or null to object');
            }

            var to = Object(target);

            for (var index = 1; index < arguments.length; index++) {
                var nextSource = arguments[index];

                if (nextSource !== null && nextSource !== undefined) {
                    for (var nextKey in nextSource) {
                        // Avoid bugs when hasOwnProperty is shadowed
                        if (Object.prototype.hasOwnProperty.call(nextSource, nextKey)) {
                            to[nextKey] = nextSource[nextKey];
                        }
                    }
                }
            }
            return to;
        },
        writable: true,
        configurable: true
    });
}

if ($headerSearch.length) {
    headerSearch({ $e: $headerSearch });
}

const $modal = $('#modal');
const $publicUpdateModal = $('#publicUpdateModal');

if ($modal.length) {
    modal({ $e: $modal });
}

if ($publicUpdateModal.length) {
    publicUpdateModal({ $e: $publicUpdateModal });
}

$('.secondary-nav').each(function () {
    secondaryNav({ $e: $(this) });
});

$('.informational').each(function () {
    informational({ $e: $(this) });
});

$('[data-toggle]').each(function () {
    toggle({ $e: $(this) });
});

$('.image').each(function () {
    image({ $e: $(this) });
});

$('.video').each(function () {
    video({ $e: $(this) });
});

$('.carousel').each(function () {
    carousel({ $e: $(this) });
});

$('.thumb-grid').each(function () {
    thumbGrid({ $e: $(this) });
});

$('.print-page').each(function () {
    printPage({ $e: $(this) });
});

$('.public-update__chs').each(function () {
    publicUpdate({ $e: $(this) });
});

$('.no-js').removeClass('no-js');

fbCustomerChat();
