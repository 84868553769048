import $ from 'jquery';
import { STATE_CLASSES } from 'scripts/globals';

export default function (options) {
    const defaults = {
        $e: $('.header-search')
    };

    const EXPAND_TEXT = 'Expand search';
    const COLLAPSE_TEXT = 'Collapse search';

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    els = {
        $header: $('#header'),
        $toggle: o.$e.find('.header-search__toggle'),
        $text: o.$e.find('.header-search__text'),
        $checkboxes: o.$e.find('.checkbox'),
        $clear: o.$e.find('.header-search__clear')
    };

    fn = {
        init () {
            fn.toggleClearByText();

            o.$e.on('toggle.header-search', handlers.searchToggle);
            o.$e.on('submit.header-search', handlers.searchSubmit);
            els.$text.on('keydown.header-search', handlers.textKeydown);
            els.$checkboxes.on('click.header-search', handlers.checkboxClick);
            els.$clear.on('click.header-search', handlers.clearClick);
        },

        toggleClearByText () {
            const hasText = els.$text.val();
            const isFilled = o.$e.hasClass(STATE_CLASSES.FILLED);

            if (hasText) {
                if (!isFilled) {
                    o.$e.addClass(STATE_CLASSES.FILLED);
                }
            } else if (isFilled) {
                fn.clearText();
            }
        },

        toggleCheckbox ($checkbox) {
            const $allInputs = o.$e.find('.checkbox__input');
            const $checkboxInput = $checkbox.find('.checkbox__input');
            const isActive = $checkboxInput.is(':checked');

            $allInputs.prop('checked', false);

            if (!isActive) {
                $checkboxInput.prop('checked', true);
            }
        },

        clearText () {
            o.$e.removeClass(STATE_CLASSES.FILLED);
            els.$text.val('').focus();
        },

        toggleSearch (active) {
            if (active) {
                els.$header.addClass(STATE_CLASSES.SEARCH_OPEN);
                els.$text.focus();
                els.$toggle.attr('title', COLLAPSE_TEXT);
            } else {
                els.$header.removeClass(STATE_CLASSES.SEARCH_OPEN);
                els.$text.val('').blur();
                els.$toggle.attr('title', EXPAND_TEXT);
                o.$e.removeClass(STATE_CLASSES.ACTIVE).data('active', false);
            }
        },

        search () {
            const query = o.$e.find('.header-search__text').val();
            const url = o.$e.find('.checkbox__input:checked').val();
            const defaultUrl = o.$e.find('#default-url').val();

            window.location = `${url || defaultUrl}?q=${query}`;
        },

        destroy () {
            els.$clear.off('.header-search');
            o.$e.off('.header-search');
        }
    };

    handlers = {
        searchToggle (e, active) {
            fn.toggleSearch(active);
        },

        searchSubmit (e) {
            fn.search();
            e.preventDefault();
        },

        textKeydown (e) {
            if (e.which === 13) {
                fn.search();
                e.preventDefault();
            } else if (e.which === 27) {
                fn.toggleSearch(false);
            } else {
                fn.toggleClearByText();
            }
        },

        checkboxClick (e) {
            fn.toggleCheckbox($(this));
            e.preventDefault();
        },

        clearClick (e) {
            fn.toggleSearch(false);
            e.preventDefault();
        }
    };

    fn.init();
};
