import $ from 'jquery';
import { STATE_CLASSES } from 'scripts/globals';
import carousel from 'scripts/modules/carousel';

export default function (options) {
    const defaults = {
        $e: $('.modal')
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    let mediaUrl = o.$e.data('mediaUrl');
    let originalUrl = mediaUrl || o.$e.data('url') || window.location.href;
    let isActive = false;
    let contentCache = window.localStorage;
    let modalMediaPath = null;

    els = {
        $window: $(window),
        $page: $('body'),
        $close: o.$e.find('.modal__close'),
        $content: o.$e.find('.modal__content')
    };

    fn = {

        init () {
            // we may already be active if this is a media detail view
            if (els.$page.hasClass(STATE_CLASSES.MODAL_OPEN)) {
                isActive = true;

                // in which case we should also cache the current modal's content
                contentCache.setItem(window.location.pathname, els.$content.html());
            }

            fn.initEventHandlers();
        },

        initEventHandlers () {
            els.$window.on('popstate.modal', handlers.windowPop);
            els.$page.on('click.modal', '.modal-launch', handlers.launchClick);
            els.$close.on('click.modal', handlers.closeClick);
        },

        initContent (url) {
            els.$content.find('.carousel').each(function () {
                carousel({ $e: $(this) });
            });
            contentCache.setItem(url, els.$content.html());

            // cache this once
            modalMediaPath = modalMediaPath || fn.normalizePath(url);
        },

        initClose () {
            els.$close = o.$e.find('.modal__close');
            els.$close.on('click.modal', handlers.closeClick);
        },

        open () {
            o.$e.fadeIn(() => {
                els.$page.addClass(STATE_CLASSES.MODAL_OPEN);
            });

            isActive = true;
        },

        close () {
            history.replaceState(null, null, originalUrl);

            o.$e.fadeOut(() => {
                els.$page.removeClass(STATE_CLASSES.MODAL_OPEN);
            });

            isActive = false;
        },

        load (url) {
            const cached = contentCache.getItem(url);
            if (cached !== null) {
                els.$content.html(cached);
            } else {
                els.$content.load(`${url} #modal-content`, function () {
                    fn.initContent(url);
                });
            }
        },

        destroy () {
            els.$close.off('.modal');
            els.$page.off('.modal');
            els.$window.off('.modal');
        },

        isOriginalNav () {
            const windowBits = window.location.href.split('/');
            const originalBits = originalUrl.split('/');

            // we know there will be a trailing slash in the urls, so the final element
            // of the list is empty and we want, rather, the penultimate element
            return (
                windowBits[windowBits.length - 2] === originalBits[originalBits.length - 2]
            );
        },

        normalizePath (url) {
            // remove the last url segment, which should be a variable id
            const bits = url.split('/');
            return bits.slice(0, bits.length - 2).join('/');
        },

        modalMediaNav () {
            if (fn.normalizePath(window.location.pathname) === modalMediaPath) {
                return window.location.pathname;
            }
            return null;
        }

    };

    handlers = {
        launchClick (e) {
            const url = $(this).data('url');

            if (isActive) {
                fn.load(url);
            } else {
                fn.open();
                fn.load(url);
            }

            history.pushState({ prev: url }, null, url);

            e.preventDefault();
        },

        windowPop (e) {
            const poppedState = e.originalEvent.state || {};
            let nextUrl;

            if (poppedState.prev) {
                // we're navigating to a previous modal
                fn.load(poppedState.prev);

                if (!isActive) {
                    fn.open();
                }
            } else {
                if (fn.isOriginalNav() && isActive) {
                    // we're navigating to the page the modal was launched from.
                    // just close the modal
                    fn.close();
                } else if (nextUrl = fn.modalMediaNav()) { // eslint-disable-line no-cond-assign
                    // we're navigating to another media modal whose content we
                    // haven't cached. load and open the modal if it's closed.
                    fn.load(nextUrl);
                    if (!isActive) {
                        fn.open();
                    }
                } else {
                    // we don't know anything about the page we're navigating to.
                    // don't interfere.
                    window.location.assign(window.location.href);
                }
            }
        },

        closeClick () {
            fn.close();
        }
    };

    fn.init();
};
