import $ from 'jquery';
import PublicUpdateKeys from 'scripts/modules/public-update__keys';

export default function (options) {
    const defaults = {
        $e: $('#publicUpdateModal'),
        $zoom: 3,
        $bw: 3,
        $magnifierSize: 2
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    els = {
        $window: $(window),
        $page: $('body'),
        $content: o.$e.find('.modal__content'),
        $image: o.$e.find('.ch-photo__image')
    };

    fn = {
        init () {
            if (!els.$image) return false;
            els.$image.first().addClass('first');
            fn.initEventListener();
        },

        initEventListener () {
            els.$image.on('click', handlers.clickOpenImage);
            els.$page.on('keydown', handlers.keydownOpenImage);
            PublicUpdateKeys();
        },

        scrollModal (scroll) {
            $('#publicUpdateModal').animate({
                scrollTop: scroll
            }, 500, 'linear');
        },

        centerScroll () {
            let offset = $('.ch-photo__image.open').offset().top;
            let scroll = $('#publicUpdateModal').scrollTop();
            let targetOffset = (Math.round(offset) > 0) ? scroll + Math.abs(offset) : scroll - Math.abs(offset);
            fn.scrollModal(targetOffset);
        },

        magnify (image) {
            if (!image.parent().hasClass('magnify')) return false;
            image = image.children('div.ch-photo__wrap');
            fn.removeMagnify(image);
            els.$img = image.children('img');
            /* Create magnifier glass: */
            image.append('<div id="img-magnifier-glass" class="img-magnifier-glass"></div>');
            els.$glass = $('#img-magnifier-glass');
            els.$glass.attr('data-size', o.$magnifierSize);
            /* Set background properties for the magnifier glass: */
            els.$glass.css({
                'background-image': "url('" + els.$img.attr('raw-src') + "')",
                'background-size': (els.$img.width() * o.$zoom) + 'px ' + (els.$img.height() * o.$zoom) + 'px'
            });
            setTimeout(function () {
                els.$glass.css({
                    'background-size': (els.$img.width() * o.$zoom) + 'px ' + (els.$img.height() * o.$zoom) + 'px'
                });
            }, 500);

            /* Execute a function when someone moves the magnifier glass over the image: */
            els.$glass.on('mousemove', handlers.moveMagnifier);
            els.$img.on('mousemove', handlers.moveMagnifier);

            /* and also for touch screens: */
            els.$glass.on('touchmove', handlers.moveMagnifier);
            els.$img.on('touchmove', handlers.moveMagnifier);
        },

        removeMagnify (image) {
            $('#img-magnifier-glass').remove();
            els.$glass = null;
            els.$img = null;
        },

        magnifierMinMaxLimitEffect () {
            $('#img-magnifier-glass').addClass('limit');
            setTimeout(function () {
                $('#img-magnifier-glass').removeClass('limit');
            }, 500);
        },

        magnifierSize (size) {
            let min = 1;
            let max = 5;
            let calc = o.$magnifierSize + size;
            if (calc >= min && calc <= max) {
                $('#img-magnifier-glass').attr('data-size', calc);
                o.$magnifierSize = calc;
            } else {
                fn.magnifierMinMaxLimitEffect();
            }
        },

        zoomSize (size) {
            let min = 1;
            let max = 5;
            let calc = o.$zoom + size;
            if (calc >= min && calc <= max) {
                o.$zoom = calc;
                els.$glass.css({
                    'background-size': (els.$img.width() * o.$zoom) + 'px ' + (els.$img.height() * o.$zoom) + 'px'
                });
                handlers.moveMagnifier(els.$magnifierEvent);
            } else {
                fn.magnifierMinMaxLimitEffect();
            }
        }
    };

    handlers = {

        clickOpenImage (e) {
            if ($(this).hasClass('open')) return false;
            $('.ch-photo__image.open').removeClass('open');
            $('.ch-photo__image.first').removeClass('first');
            $(this).addClass('open');
            fn.magnify($(this));
            fn.centerScroll();
        },

        keydownOpenImage (e) {
            var img;
            let el = $('.ch-photo__image.open');
            if (el) {
                let offset = $('.ch-photo__image.open').offset().top;
                if (e.keyCode === 27) {
                    el.removeClass('open');
                    fn.removeMagnify(el);
                } else if (e.keyCode === 37) {
                    img = el.removeClass('open').prev().addClass('open');
                    fn.removeMagnify(el);
                    fn.magnify(img);
                } else if (e.keyCode === 39) {
                    img = el.removeClass('open').next().addClass('open');
                    fn.removeMagnify(el);
                    fn.magnify(img);
                } else if (e.keyCode === 189) { // zoomout
                    fn.zoomSize(-1);
                } else if (e.keyCode === 187) { // zoomin
                    fn.zoomSize(1);
                } else if (e.keyCode === 219) { // zoomout
                    fn.magnifierSize(-1);
                } else if (e.keyCode === 221) { // zoomin
                    fn.magnifierSize(1);
                }
                let scroll = $('#publicUpdateModal').scrollTop();
                let targetOffset = (Math.round(offset) > 0) ? scroll + Math.abs(offset) : scroll - Math.abs(offset);
                fn.scrollModal(targetOffset);
            }
        },

        moveMagnifier (e) {
            var pos, x, y, w, h;
            els.$magnifierEvent = e;
            /* Prevent any other actions that may occur when moving over the image */
            e.preventDefault();
            /* Get the cursor's x and y positions: */
            pos = handlers.getCursorPos(e);
            x = pos.x;
            y = pos.y;
            w = els.$glass[0].offsetWidth / 2;
            h = els.$glass[0].offsetHeight / 2;
            /* Prevent the magnifier glass from being positioned outside the image: */
            if (x > els.$img.width() - (w / o.$zoom)) { x = els.$img.width() - (w / o.$zoom); }
            if (x < w / o.$zoom) { x = w / o.$zoom; }
            if (y > els.$img.height() - (h / o.$zoom)) { y = els.$img.height() - (h / o.$zoom); }
            if (y < h / o.$zoom) { y = h / o.$zoom; }

            els.$glass.css({
                /* Set the position of the magnifier glass: */
                'left': (x - w) + 'px',
                'top': (y - h) + 'px',
                /* Display what the magnifier glass "sees": */
                'background-position': '-' + ((x * o.$zoom) - w + o.$bw) + 'px -' + ((y * o.$zoom) - h + o.$bw) + 'px'
            });
        },

        getCursorPos (e) {
            let a = els.$img[0].getBoundingClientRect();
            let x = 0;
            let y = 0;
            e = e || els.$window.event;
            /* Calculate the cursor's x and y coordinates, relative to the image: */
            x = e.pageX - a.left;
            y = e.pageY - a.top;
            /* Consider any page scrolling: */
            x = x - els.$window[0].pageXOffset;
            y = y - els.$window[0].pageYOffset;
            return { x: x, y: y };
        }
    };

    fn.init();
};
