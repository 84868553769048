import $ from 'jquery';

export default function (options) {
    const defaults = {
        prefix: 'public-update'
    };

    let o;
    let els;
    let fn;
    let handlers;
    let defaultsMore;

    defaultsMore = {
        keys: '#' + defaults.prefix + '__keys',
        info: '.keys__info',
        map: '.keys__map',
        close: '.keys__close'
    };

    o = Object.assign(defaults, defaultsMore);
    o = Object.assign(o, options);

    els = {
        $page: $('body'),
        $keys: $(o.keys),
        $info: $(o.keys).find(o.info),
        $map: $(o.keys).find(o.map),
        $close: $(o.keys).find(o.close)
    };

    fn = {
        init () {
            // init the event lisntener function
            fn.initEventListener();
        },

        initEventListener () {
            els.$info.on('click', handlers.info);
            els.$close.on('click', handlers.close);
            els.$page.on('keydown', handlers.keydown);
        }
    };

    handlers = {
        info (e) {
            els.$map.addClass('open');
        },
        close (e) {
            els.$map.removeClass('open');
        },
        keydown (e) {
            if (e.keyCode === 27) {
                handlers.close();
            }
        }
    };

    fn.init();
};
