export const STATE_CLASSES = {
    ACTIVE: 'is-active',
    SELECTED: 'is-selected',
    LOADED: 'is-loaded',
    FILLED: 'is-filled',
    STREAMING: 'is-streaming',
    SEARCH_OPEN: 'is-search-open',
    MODAL_OPEN: 'is-modal-open'
};

export const GRID = {
    COLUMN_COUNT: 12
};

export const BREAKPOINTS = {
    SMALL: 640,
    MEDIUM: 840,
    LARGE: 1024
};

export const COLORS = {
    BLACK: '#000000',
    PEACH: '#f7f2f3',
    EBB: '#ede4e5',
    LOLA: '#e6dddf',
    PINK: '#bca6aa',
    BAZAAR: '#9c8387',
    RED: '#ff3a20',
    DARK_RED: '#e62b12',
    YELLOW: '#f9e79e',
    DARK_YELLOW: '#edda8c'
};
