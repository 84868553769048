import $ from 'jquery';
import debounce from 'lodash/debounce';
import imagesLoaded from 'imagesloaded';

export default function (options) {
    const defaults = {
        $e: $('.informational')
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    els = {
        $window: $(window),
        $aside: o.$e.find('.informational__aside')
    };

    fn = {
        init () {
            imagesLoaded(o.$e[0], fn.resize);

            els.$window
                .on('resize.informational', debounce(handlers.windowResize, 250))
                .on('imageload', handlers.windowResize);
        },

        resize () {
            // because the aside element is absolutely positioned (outside of the DOM
            // flow) we ensure informationals are tall enough to accomodate it here
        },

        destroy () {
            els.$window.off('.informational');
        }
    };

    handlers = {
        windowResize () {
            fn.resize();
        }
    };

    fn.init();
};
