import $ from 'jquery';

$(document).ready(function ($) {
    if ((window.location.href.includes('/engage/impact/') || window.location.href.includes('/pressroom/') || window.location.href.includes('/edit/preview/') || window.location.href.includes('/about/')) && $('.like-slider-section').length !== 0) {
        const textParent = $('article.informational .stream--informational');
        const textBlocks = $(textParent).find('.stream__block .rich-text');
        const sidebar = $('.informational__aside');
        const footer = $('footer.informational__footer');

        const links = createLinks(textBlocks);

        insertLinks(links, sidebar);
        initPageBlocks(textBlocks);
        insertFooterLinks(footer, textBlocks);
    }
});

$(document).on('click', '.pressroom-link', function (e) {
    e.preventDefault();
    triggerLinks($(this));
});

$(document).on('click', '.pressroom-footer-link', function (e) {
    e.preventDefault();
    const neededId = $(this).data('id');
    $(`.nav.nav--sub .nav__item a[data-id=${neededId}]`).click();
});

function insertFooterLinks (footer, textBlocks) {
    const [baseUrl, activeId] = window.location.href.split('#');
    let linksNumber = $(textBlocks).length;
    const links = [];
    const titles = [];
    const ids = [];

    $.each($(textBlocks), function (i) {
        const textBlock = $(textBlocks)[i];
        const blockTitle = $(textBlock).find('h4');
        if (blockTitle.length) {
            const id = strToSnakeStr($(blockTitle).text());

            links.push(`${baseUrl}#${id}`);
            titles.push($(blockTitle).text());
            ids.push(id);
        } else {
            linksNumber--;
        }
    });
    console.log(ids);
    console.log(linksNumber);
    if (ids.length > 1) {
        let footerNav = '<nav class="pager-nav">';
        if (!activeId || ids[0] === activeId) { // first page, no prev button
            footerNav += `<a class="pager-nav__link pager-nav__link--next link pressroom-footer-link" href="${links[1]}" data-id="${ids[1]}">${titles[1]}</a>`;
        } else if (ids[linksNumber - 1] === activeId) { // last page, no next button
            footerNav += `<a class="pager-nav__link pager-nav__link--previous link pressroom-footer-link" href="${links[linksNumber - 2]}" data-id="${ids[linksNumber - 2]}">${titles[linksNumber - 2]}</a>`;
        } else {
            const index = ids.includes(activeId) && ids.findIndex(tmpId => tmpId === activeId);
            footerNav += `<a class="pager-nav__link pager-nav__link--previous link pressroom-footer-link" href="${links[index - 1]}" data-id="${ids[index - 1]}">${titles[index - 1]}</a>`;
            footerNav += `<a class="pager-nav__link pager-nav__link--next link pressroom-footer-link" href="${links[index + 1]}" data-id="${ids[index + 1]}">${titles[index + 1]}</a>`;
        }
        footerNav += '</nav">';

        $(footer).prepend(footerNav);
    }
}

function triggerLinks (link) {
    $('.link.pressroom-link').removeClass('is-active');
    $(link).addClass('is-active');
    const href = $(link).attr('href');
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    window.history.pushState(null, null, href);
    window.location.reload();
}

function initPageBlocks (textBlocks) {
    $.each($(textBlocks), function (i) {
        const textBlock = $(textBlocks)[i];
        if ($(textBlock).find('h4').length) {
            const id = strToSnakeStr($(textBlock).find('h4').text());
            $(textBlock).attr('id', id);
            $(textBlock).parent().attr('data-rel-id', id);
        }
    });

    const activeId = window.location.href.split('#')[1];
    const allBlocks = $('article.informational .stream--informational .stream__block');

    $.each($(allBlocks), function (i) {
        $(allBlocks[i]).addClass('inactive');
        const currentBlock = $(allBlocks[i]);
        const currentRelId = $(currentBlock).data('rel-id');
        if (!currentRelId) {
            if (i !== 0) $(currentBlock).attr('data-rel-id', $(allBlocks[i - 1]).data('rel-id'));
        }
    });

    if (activeId) {
        $(`.stream__block[data-rel-id="${activeId}"]`).removeClass('inactive').addClass('active');
        const activeBlocks = $(`.stream__block.active`);
        $(activeBlocks[0]).css('margin-top', '0');
        if ($('.impact-post').length) {
            const text = $(`.stream__block[data-rel-id="${activeId}"] h4`).text();
            $(`.stream__block[data-rel-id="${activeId}"] h4`).hide();
            $('.impact-post .informational__header h2').after(`<h1 class="h3 color-pink">${text}</h1>`);
        }
    } else {
        const blockId = $(textBlocks[0]).attr('id');
        $(`.stream__block[data-rel-id="${blockId}"]`).removeClass('inactive').addClass('active');
        if ($('.impact-post').length) {
            const text = $(`.stream__block[data-rel-id="${blockId}"] h4`).text();
            $(`.stream__block[data-rel-id="${blockId}"] h4`).hide();
            $('.impact-post .informational__header h2').after(`<h1 class="h3 color-pink">${text}</h1>`);
        }
    }
}

function createLinks (textBlocks) {
    const [baseUrl] = window.location.href.split('#');
    const links = [];

    $.each($(textBlocks), function (i) {
        const textBlock = $(textBlocks)[i];
        const blockTitle = $(textBlock).find('h4');
        if (blockTitle.length) {
            const id = strToSnakeStr($(blockTitle).text());
            const title = $(blockTitle).text();
            const active = window.location.href.split('#')[1];
            let url = '';
            if (!active) {
                if (i === 0) {
                    url = `<li class="nav__item"><a href="${baseUrl}#${id}" data-id="${id}" class="nav__link link pressroom-link is-active">${title}</a></li>`;
                } else {
                    url = `<li class="nav__item"><a href="${baseUrl}#${id}" data-id="${id}" class="nav__link link pressroom-link">${title}</a></li>`;
                }
            } else {
                if (active === id) {
                    url = `<li class="nav__item"><a href="${baseUrl}#${id}" data-id="${id}" class="nav__link link pressroom-link is-active">${title}</a></li>`;
                } else {
                    url = `<li class="nav__item"><a href="${baseUrl}#${id}" data-id="${id}" class="nav__link link pressroom-link">${title}</a></li>`;
                }
            }
            links.push(url);
        }
    });

    return links;
};

function insertLinks (links, sidebar) {
    let linkContainer = '<nav class="nav nav--sub"><ul class="nav__list">';
    links.forEach(link => { linkContainer += link; });
    linkContainer += '</ul></div>';

    if ($(sidebar).find('.related-film-links').length || $(sidebar).find('.informational__ctas').length) {
        if ($(sidebar).find('.related-film-links').length) {
            $(linkContainer).insertBefore($(sidebar).find('.related-film-links'));
        } else {
            $(linkContainer).insertBefore($(sidebar).find('.informational__ctas'));
        }
    } else {
        $(sidebar).append($(linkContainer));
    }
}

function strToSnakeStr (s) {
    return s.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
        .map(x => x.toLowerCase())
        .join('_');
}
