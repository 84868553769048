import $ from 'jquery';

$(window).on('resize', function () {
    introImageSize();
});

$(document).ready(function () {
    introImageSize();

    $('.rich-text--custom-columns .column').each(function (element) {
        if ($.trim($(this).text()) === '') {
            $(this).remove();
        }
    });

    // set active and inactive chapters containers.
    $('.public-update__chs').first().addClass('active');

    // set active and inactive chapters.
    $('.public-update__chs').each(function () {
        $(this).find('.public-update__ch').first().addClass('active');
        $(this).find('.public-update__ch').not('.active').addClass('inactive');
    });

    var launchButtons = $('.slide-intro .intro__volume .public-update__launch');
    $(launchButtons).each(function (e) {
        var volWrap = $(this).closest('.public-update__chs');
        var chapLaunchBtns = volWrap.find('.public-update__launch:not(".slide-btn")');
        var btnsCount = chapLaunchBtns.length;
        var newChapterIndex = getRandomChapterIndex(btnsCount);
        var launchLink = $(chapLaunchBtns[newChapterIndex]).attr('href');
        $(this).attr('href', launchLink);
    });

    if ((/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) || (!!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform))) {
        $('.intro__background').addClass('ios');
        $('.intro__background').parent().addClass('ios');
    }
});

let setEven = false;

$(document).on('click', '.public-update__chs .icon--next .nav__icon-btn', function (e) {
    e.preventDefault();
    switchActiveVolume(e.target);

    let currentChapter = getCurrentChapter();
    let chaptersCount = $('.public-update__chs.active .public-update__ch').length;

    if (chaptersCount > 1) {
        let nextChapter = $(currentChapter).next();
        if (nextChapter.length === 0) {
            nextChapter = currentChapter.siblings().first();
        }
        toggleChapter(currentChapter, nextChapter);
    };
});

$(document).on('click', '.public-update__chs .icon--prev .nav__icon-btn', function (e) {
    e.preventDefault();
    switchActiveVolume(e.target);

    let currentChapter = getCurrentChapter();
    let chaptersCount = $('.public-update__chs.active .public-update__ch').length;

    if (chaptersCount > 1) {
        let prevChapter = $(currentChapter).prev();
        if (prevChapter.length === 0) {
            prevChapter = currentChapter.siblings().last();
        }
        toggleChapter(currentChapter, prevChapter);
    };
});

$(document).on('click', '.public-update__chs .icon--refresh .nav__icon-btn', function (e) {
    e.preventDefault();
    switchActiveVolume(e.target);

    let currentChapter = getCurrentChapter();
    let currentChapterIndex = currentChapter.index();
    let chaptersCount = $('.public-update__chs.active .public-update__ch').length;

    let newChapterIndex = getRandomChapterIndex(chaptersCount);

    while (newChapterIndex === currentChapterIndex) {
        newChapterIndex = getRandomChapterIndex(chaptersCount);
    }

    if (chaptersCount > 1) {
        console.log(newChapterIndex);
        toggleChapter(currentChapter, currentChapter.parent().children().eq(newChapterIndex));
    };
});

function switchActiveVolume (elm) {
    $('.public-update__chs').removeClass('active inactive');
    $(elm).closest('.public-update__chs').addClass('active');
};

function getCurrentChapter () {
    return $('.public-update__chs.active .public-update__ch.active');
};

function toggleChapter (currentChapter, newActiveChapter) {
    switchSides(newActiveChapter);
    $(currentChapter).find('.artist-statement').removeClass('alt');
    $(currentChapter).removeClass('active').addClass('inactive');
    $(newActiveChapter).removeClass('inactive').addClass('active');
};

function getRandomChapterIndex (max) {
    return Math.floor(Math.random() * Math.floor(max));
};

function switchSides (activeChapter) {
    setEven = !setEven;

    if (setEven) {
        activeChapter.parent().children().addClass('even');
    } else {
        activeChapter.parent().children().removeClass('even');
    }
};

$(document).on('click', '.icon--close .nav__icon-btn', function (e) {
    e.preventDefault();
    window.close();
});

$(document).on('click', '.button.artist-statement_control', function (e) {
    toggleArtistStatement(e, $(this));
});

$(document).on('click', '.artist-statement.alt .icon__btn', function (e) {
    toggleArtistStatement(e, $(this));
});

$(document).on('click', '.scroll-down-intro a', function (e) {
    if (this.hash !== '') {
        event.preventDefault();

        var hash = this.hash;
        $('html, body').animate({
            scrollTop: $(hash).offset().top
        }, 800, function () {
            window.location.hash = hash;
        });
    }
});

function toggleArtistStatement (e, elm) {
    e.preventDefault();
    $(elm).closest('.public-update__ch.active').find('.artist-statement').toggleClass('alt');
};

function introImageSize () {
    if ($('#intro-responsive-image').length > 0) {
        const imageWidth = $('#intro-responsive-image').width();
        const imageHeight = $('#intro-responsive-image').height();
        const containerWidth = $('.intro--behind-header.responsive-image').width();

        let aspectRatio = 0;

        if (imageWidth > imageHeight) {
            aspectRatio = imageWidth / imageHeight;
        } else {
            aspectRatio = imageHeight / imageWidth;
        }

        $('.intro--behind-header.responsive-image').css({
            'min-height': `${containerWidth / aspectRatio}px`
        });
        $('.intro--behind-header.responsive-image .intro__background').css({
            height: `${containerWidth / aspectRatio}px`
        });
    }
}
