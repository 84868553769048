import $ from 'jquery';
import { COLORS } from 'scripts/globals';
import WaveSurfer from 'scripts/modules/wavesurfer';
import PublicUpdateKeys from 'scripts/modules/public-update__keys';

export default function (options) {
    const defaults = {
        prefix: 'public-update__ch-audio'
    };

    let o;
    let els;
    let fn;
    let handlers;
    let defaultsMore;

    defaultsMore = {
        container: '#' + defaults.prefix,
        player: '#' + defaults.prefix + '__player'
    };

    o = Object.assign(defaults, defaultsMore);

    o = Object.assign(o, options);

    els = {
        $playing: false,
        $page: $('body'),
        $player: $(o.player),
        $container: $(o.container),
        $audio: $(o.container).find('audio'),
        $playPauseBtn: $(o.container).find('.player__start-btn'),
        $audioChapters: $(o.container).find('.player__skip-btn'),
        $chapters: [],
        $ws: null
    };

    fn = {
        init () {
            if (!els.$player || !els.$audio[0]) return false;
            els.$audio[0].load();
            setTimeout(fn.initWaveForm, 1500);
            fn.initEventListener();
        },

        initEventListener () {
            els.$chapterListClose = els.$container.find('.icon--close .icon__btn');
            els.$playPauseBtn = els.$player.find('.player__start-btn');
            els.$audioChapters = els.$player.find('.player__skip-btn');

            $(els.$playPauseBtn).on('click', handlers.toggleAudio);
            $(els.$audioChapters).on('click', handlers.skip);
            $(els.$chapterListClose).on('click', handlers.hideChapter);
            $(els.$page).on('keydown', handlers.keyDown);

            PublicUpdateKeys();
        },

        initWaveForm () {
            let id = '#' + o.prefix + '__waveform';
            let height = $(id).outerHeight();
            els.$ws = WaveSurfer.create({
                container: id,
                scrollParent: false,
                waveColor: COLORS.PINK,
                progressColor: COLORS.RED,
                height: parseInt(height),
                interact: false,
                cursorWidth: 0,
                cursorColor: COLORS.DARK_RED
            });
            els.$ws.load(els.$audio[0].src);
            els.$ws.on('ready', function () {
                $('#public-update__ch-audio__player .player__start-btn').removeClass('hidden');
                $('#public-update__ch-audio__player .loading').addClass('hidden');
            });
            $(id).on('click', handlers.toggleAudio);
            els.$ws.on('audioprocess', handlers.updateDisplay);
            els.$ws.on('finish', handlers.finish);
            handlers.updateChapterPostion();
        },

        playVideo (item) {
            let video = item.find('video');
            if (video.length > 0) {
                video[0].play();

                video[0].onended = function () {
                    handlers.hideChapter();
                };
            }
        }
    };

    handlers = {
        toggleAudio (e) {
            if (els.$ws.isPlaying()) {
                els.$playPauseBtn.find('.play').show();
                els.$playPauseBtn.find('.pause').hide();
                els.$ws.pause();
            } else {
                els.$playPauseBtn.find('.play').hide();
                els.$playPauseBtn.find('.pause').show();
                els.$ws.play();
            }
        },

        skip (e) {
            let value = parseInt($(this).attr('value'));
            els.$ws.skip(value - els.$ws.getCurrentTime());
            if (!els.$ws.isPlaying()) {
                els.$ws.play();
            }
            handlers.showChapter(value);
        },

        showChapter (value) {
            let item = $('#public-update__ch-audio__info__item-' + value);
            $('.ch-audio__info').addClass('show');
            item.addClass('active').addClass('animate');
            $('html, body, .modal').css({ 'overflow': 'hidden' });
            fn.playVideo(item);
        },

        hideChapter (e) {
            $('.ch-audio__info').removeClass('show');
            $('.info__item.active').removeClass('active').removeClass('animate');
            $('html, body, .modal').css({ 'overflow': 'auto' });
        },

        keyDown (e) {
            e.preventDefault();
            if (e.keyCode === 27) {
                handlers.hideChapter(e);
            } else if (e.keyCode === 32) {
                handlers.toggleAudio(e);
            }
        },

        updateChapterPostion (e) {
            let el = els.$audio[0];
            let duration = el.duration;
            els.$audioChapters.each(function () {
                let value = parseInt($(this).attr('value'));
                if (value === 0) {
                    $(this).hide();
                    $('#public-update__ch-audio__info__item-' + value).addClass('active');
                } else {
                    els.$chapters.push(value);
                    let position = Math.round((value / duration) * 100);
                    $(this).css({ 'left': (position) + '%' });
                }
            });
        },

        updateDisplay (e) {
            let index = els.$chapters.indexOf(Math.round(els.$ws.getCurrentTime()));
            if (els.$ws.getCurrentTime() > 0) {
                els.$player.removeClass('stop');
            }
            if (index !== -1) {
                $('.info__item.active').removeClass('active').removeClass('animate');
                handlers.showChapter(els.$chapters[index]);
            }
        },

        finish (e) {
            $(els.$chapterListClose).trigger('click');
            els.$player.addClass('stop');
            els.$ws.stop();
            $(els.$playPauseBtn).find('.play').show();
            $(els.$playPauseBtn).find('.pause').hide();
        }

    };

    fn.init();
};
