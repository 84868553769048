import $ from 'jquery';
import lozad from 'lozad';
import { STATE_CLASSES } from 'scripts/globals';

export default function (options) {
    // adds styles to the video container element to make the embed responsive
    // based on the embed's width and height attributes (if set)

    const defaults = {
        $e: $('.video')
    };

    let o;
    let els;
    let fn;
    let handlers;
    let observer;

    o = Object.assign(defaults, options);

    els = {
        $iframe: o.$e.find('.video__iframe')
    };

    fn = {
        init () {
            const width = els.$iframe.attr('width');
            const height = els.$iframe.attr('height');

            if (width && height) {
                o.$e.css('padding-top', `${100 * height / width}%`);
            }

            observer = lozad(els.$iframe[0], { loaded: handlers.videoLoad });
            observer.observe();
        },

        load () {
            o.$e.addClass(STATE_CLASSES.LOADED);
        },

        destroy () {
            o.$e.css('padding-top', '');
            o.$e.off('.video');
        }
    };

    handlers = {
        videoLoad () {
            fn.load();
        }
    };

    fn.init();
};
