import $ from 'jquery';
import debounce from 'lodash/debounce';
import Flickity from 'flickity';
import 'flickity-imagesloaded';
import { STATE_CLASSES, BREAKPOINTS } from 'scripts/globals';

export default function (options) {
    const defaults = {
        $e: $('.carousel'),
        flickityOptions: {
            adaptiveHeight: true,
            imagesLoaded: true,
            cellAlign: 'left',
            pageDots: false,
            arrowShape: 'M1.3 49h97.5M58.6 8.7L99 49l-1.4 1.5-40.4 40.3',
            groupCells: true
        }
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    let flkty;

    els = {
        $window: $(window)
    };

    fn = {
        init () {
            fn.setup();
            o.$e.addClass(STATE_CLASSES.LOADED);
            els.$window.on('resize.carousel', debounce(handlers.windowResize, 250));
        },

        setup () {
            fn.destroyFlkty();
            flkty = new Flickity(o.$e[0], o.flickityOptions);
        },

        destroyFlkty () {
            if (flkty) {
                flkty.destroy();
                flkty = null;
            }
        },

        destroy () {
            fn.destroyFlkty();
            o.$e.removeClass(STATE_CLASSES.LOADED);
            els.$window.off('.carousel');
        }
    };

    handlers = {
        windowResize () {
            const isWrapSize = els.$window.width() <= BREAKPOINTS.LARGE;
            const wrapAround = o.flickityOptions['wrapAround'];

            if (isWrapSize && !wrapAround) {
                o.flickityOptions['wrapAround'] = true;
                fn.setup();
            } else if (!isWrapSize && wrapAround) {
                o.flickityOptions['wrapAround'] = false;
                fn.setup();
            }
        }
    };

    fn.init();
};
