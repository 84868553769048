import $ from 'jquery';
import PublicUpdateChPhoto from 'scripts/modules/public-update__ch-photo';
import PublicUpdateChAudio from 'scripts/modules/public-update__ch-audio';
import { STATE_CLASSES } from 'scripts/globals';

export default function (options) {
    const defaults = {
        prefix: 'public-update',
        container: '#publicUpdateModal',
        launch: '',
        zoom: 3,
        bw: 3,
        magnifierSize: 2
    };

    let o;
    let els;
    let fn;
    let handlers;
    let defaultsMore;

    defaultsMore = {
        nav: '.' + defaults.prefix + '__nav',
        launch: '.' + defaults.prefix + '__launch',
        photo: '#' + defaults.prefix + '__ch-photo',
        audio: '#' + defaults.prefix + '__ch-audio'
    };

    o = Object.assign(defaults, defaultsMore);
    o = Object.assign(o, options);

    els = {
        $window: $(window),
        $page: $('body'),
        $container: $(o.container),
        $content: $(o.container).find('.modal__content'),
        $close: null
    };

    fn = {
        init () {
            fn.initEventListener();
            fn.initChPhoto();
            fn.initChAudio();
        },

        initEventListener () {
            els.$page.find(o.launch).on('click', handlers.launchClick);
        },

        initContent () {
            fn.initNav();
            fn.initClose();
            fn.initChPhoto();
            fn.initChAudio();
        },

        initNav () {
            els.$nav = els.$container.find(o.nav);
            els.$nav.find('.nav__icon-btn').on('click', handlers.launchClick);
        },

        initClose () {
            els.$close = els.$container.find('.icon--close .nav__icon-btn');
            els.$close.on('click', handlers.closeClick);
        },

        initChPhoto () {
            if (!els.$container.find(o.photo).length) return false;
            PublicUpdateChPhoto();
        },

        initChAudio () {
            if (!$(o.audio).length) return false;
            PublicUpdateChAudio();
        },

        open () {
            els.$container.fadeIn(() => {
                els.$page.addClass(STATE_CLASSES.MODAL_OPEN);
            });
        },

        close () {
            els.$container.fadeOut(() => {
                els.$page.removeClass(STATE_CLASSES.MODAL_OPEN);
            });
        },

        load (url) {
            els.$content.load(`${url} #modal-content`, fn.initContent);
            history.replaceState(null, null, url);
        }
    };

    handlers = {
        closeClick (e) {
            fn.close();
            e.preventDefault();
        }
    };

    fn.init();
};
