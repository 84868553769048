import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Flickity from 'flickity';
import { STATE_CLASSES } from 'scripts/globals';

// make Flickity jQuery plugins
Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);

export default function (options) {
    const defaults = {
        $e: $('.secondary-nav'),
        flickityOptions: {
            adaptiveHeight: false,
            contain: true,
            pageDots: false,
            prevNextButtons: false,
            groupCells: true,
            setGallerySize: false
        }
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    els = {
        $window: $(window),
        $list: o.$e.find('.secondary-nav__list'),
        $active: o.$e.find(`.secondary-nav__item.${STATE_CLASSES.ACTIVE}`)
    };

    fn = {
        init () {
            fn.setup();
            els.$window.on('load', handlers.listLoad);
        },

        setup () {
            els.$list.flickity(o.flickityOptions);
            o.$e.addClass(STATE_CLASSES.LOADED);

            if (els.$active.length) {
                fn.goToActive();
            }
        },

        goToActive () {
            els.$list.flickity('select', els.$active.index(), false, true);
        },

        resize () {
            els.$list.flickity('resize');
        },

        destroy () {
            els.$list.flickity('destroy');
            o.$e.removeClass(STATE_CLASSES.LOADED);
        }
    };

    handlers = {
        listLoad () {
            fn.resize();
        }
    };

    fn.init();
};
