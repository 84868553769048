import $ from 'jquery';

export default function (options) {
    const defaults = {
        $e: $('.print-page')
    };

    let o;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    fn = {
        init () {
            o.$e.on('click.print-page', handlers.elClick);
        },

        print () {
            window.print();
        },

        destroy () {
            o.$e.off('.print-page');
        }
    };

    handlers = {
        elClick (e) {
            fn.print();
            e.preventDefault();
        }
    };

    fn.init();
};
