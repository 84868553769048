import $ from 'jquery';
import jQueryBridget from 'jquery-bridget';
import Packery from 'packery';
import imagesLoaded from 'imagesloaded';

// make Packery and imagesLoaded jQuery plugins
jQueryBridget('packery', Packery, $);
imagesLoaded.makeJQueryPlugin($);

export default function (options) {
    const defaults = {
        $e: $('.thumb-grid')
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    let loadUrl;
    let loading = false;
    let page = 1;

    els = {
        $window: $(window),
        $grid: o.$e.find('.thumb-grid__grid'),
        $loadMore: o.$e.find('.load-more'),
        $loadMoreButton: o.$e.find('.load-more__button')
    };

    fn = {
        init () {
            fn.initPackery();

            if (els.$loadMore.length) {
                loadUrl = els.$loadMoreButton.data('url');
                els.$loadMoreButton.on('click.thumb-grid', handlers.loadMoreClick);
            }

            els.$window.on('load.thumb-grid', handlers.windowLoad);
        },

        initPackery () {
            els.$grid.packery({
                columnWidth: '.thumb-grid__column-sizer',
                gutter: '.thumb-grid__gutter-sizer',
                itemSelector: '.thumb',
                percentPosition: true
            });
        },

        addThumbs (html) {
            const $html = $(html);
            els.$grid.append($html).packery('appended', $html);
            els.$grid.imagesLoaded(fn.redraw);
        },

        redraw () {
            els.$grid.packery('layout');
        },

        loadMore () {
            if (loading) {
                return;
            }

            loading = true;

            page++;

            $.ajax({
                url: `${loadUrl}?page=${page}`,
                data: { format: 'json' },
                error: handlers.dataLoadError,
                success: handlers.dataLoadSuccess
            });
        },

        removeLoadMore () {
            els.$loadMore.remove();
        },

        destroy () {
            els.$grid.packery('destroy');
            o.$e.off('.thumb-grid');
        }
    };

    handlers = {
        loadMoreClick (e) {
            fn.loadMore();

            e.preventDefault();
        },

        dataLoadError (response, status) {
            loading = false;

            console.error(status, response.responseText);
        },

        dataLoadSuccess (data) {
            loading = false;

            const html = data.html;
            const hasNext = data.has_next;

            fn.addThumbs(html);

            if (!hasNext) {
                fn.removeLoadMore();
            }
        },

        windowLoad () {
            fn.redraw();
        }
    };

    fn.init();
};
