import $ from 'jquery';

$(document).ready(function ($) {
    if ($('.rich-text.text-with-embed').length) {
        $.each($('.rich-text code:contains(\'photo\')'), function (elem) {
            const codeElement = $(this);
            const container = $(this).closest('.stream__block');
            const embedHtml = container.find('.embedded-code.hidden');
            codeElement.replaceWith(embedHtml);
            embedHtml.before('<div class="loading google-album"><span class="loader"></span></div>');
            embedHtml.removeClass('hidden');
        });
        $.each($('.rich-text code:contains(\'video\')'), function (elem) {
            const container = $(this).closest('.stream__block');
            const embedHtml = container.find('.embedded-code.hidden');
            embedHtml.removeClass('hidden');
        });
        $.each($('.rich-text code:contains(\'dropbox\')'), function (elem) {
            // const script = $('<script>', {
            //     // type: 'text/javascript',
            //     // src: 'https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js',
            //     type: 'text/javascript',
            //     src: 'https://www.dropbox.com/static/api/2/dropins.js',
            //     id: 'dropboxjs',
            //     'data-app-key': 'mhbxkjl0se4axqu'
            // });
            // script[0].setAttribute('async', '');
            // $('body').append(script);

            const codeElement = $(this);
            const container = $(this).closest('.stream__block');
            const embedHtml = container.find('.embedded-code.hidden');
            codeElement.replaceWith(embedHtml);
            // embedHtml.before('<div class="loading google-album"><span class="loader"></span></div>');
            embedHtml.removeClass('hidden');
        });

        setTimeout(function () {
            const script = $('<script>', {
                type: 'text/javascript',
                src: 'https://cdn.jsdelivr.net/npm/publicalbum@latest/embed-ui.min.js'
            });

            script[0].setAttribute('async', '');

            $('body').append(script);
            $('.loading.google-album').remove();
        }, 2000);
    }
});
