import $ from 'jquery';
import lozad from 'lozad';
import imagesLoaded from 'imagesloaded';
import { STATE_CLASSES } from 'scripts/globals';

export default function (options) {
    const defaults = {
        $e: $('.image')
    };

    let o;
    let els;
    let fn;
    let handlers;
    let observer;

    o = Object.assign(defaults, options);

    els = {
        $window: $(window)
    };

    fn = {
        init () {
            observer = lozad(o.$e[0], { loaded: handlers.imageLoad });
            observer.observe();
        },

        load () {
            o.$e.addClass(STATE_CLASSES.LOADED);
            els.$window.trigger('imageload');
        },

        destroy () {
            o.$e.removeClass(STATE_CLASSES.LOADED);
        }
    };

    handlers = {
        imageLoad () {
            imagesLoaded(o.$e[0], fn.load);
        }
    };

    fn.init();
};
