import $ from 'jquery';
import { STATE_CLASSES } from 'scripts/globals';

export default function (options) {
    // toggle 'active' class & trigger custom 'toggle' event on element
    // or an associated target element on user click.
    // (assumes element is deactivated by default)

    const defaults = {
        $e: $('[data-toggle]'),
        $target: null
    };

    let o;
    let els;
    let fn;
    let handlers;

    o = Object.assign(defaults, options);

    els = {
        $window: $(window),
        $target: null
    };

    fn = {
        init () {
            const htmlTargetSelector = o.$e.data('toggle');
            const $htmlTarget = (htmlTargetSelector) ? $(htmlTargetSelector) : null;

            els.$target = $htmlTarget || o.$target || o.$e;

            o.$e.on('click.toggle', handlers.toggleClick);
            els.$window.on('resize.toggle', handlers.windowResize);
        },

        toggle () {
            const isTargetActive = els.$target.data('active');
            const active = !isTargetActive;

            if (active) {
                els.$target.addClass(STATE_CLASSES.ACTIVE);
            } else {
                els.$target.removeClass(STATE_CLASSES.ACTIVE);
            }

            els.$target.data('active', active);
            els.$target.trigger('toggle', [active]);
        },

        destroy () {
            o.$e.off('.toggle');
            els.$window.off('.toggle');
        }
    };

    handlers = {
        toggleClick (e) {
            fn.toggle();
            e.preventDefault();
        },

        windowResize () {
            if (els.$target.data('active')) {
                fn.toggle();
            }
        }
    };

    fn.init();
};
