/* global FB */

import $ from 'jquery';

export default function () {
    let els;
    let fn;
    let handlers;
    let init;
    let active;

    els = {
        $toggle: $('.fb-customerchat-toggle')
    };

    fn = {
        init () {
            window.fbAsyncInit = fn.setup;

            (function (d, s, id) {
                var js;
                var fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s); js.id = id;
                js.src = '//connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
                fjs.parentNode.insertBefore(js, fjs);
            }(document, 'script', 'facebook-jssdk'));
        },

        setup () {
            FB.init({
                autoLogAppEvents: true,
                xfbml: true,
                version: 'v3.1'
            });

            FB.Event.subscribe('customerchat.load', handlers.chatLoad);
            FB.Event.subscribe('customerchat.dialogShow', handlers.chatDialogShow);
            FB.Event.subscribe('customerchat.dialogHide', handlers.chatDialogHide);

            els.$toggle.on('click.fb-customerchat', handlers.toggleClick);
        },

        toggle () {
            if (active) {
                FB.CustomerChat.hideDialog();
            } else {
                FB.CustomerChat.showDialog();
            }
        },

        destroy () {
            els.$toggle.off('.fb-customerchat');
        }
    };

    handlers = {
        chatLoad () {
            els.$toggle.fadeIn(() => { init = true; });
        },

        chatDialogShow () {
            active = true;
        },

        chatDialogHide () {
            active = false;
        },

        toggleClick (e) {
            if (!init) { return; }
            fn.toggle();
            e.preventDefault();
        }
    };

    fn.init();
};
